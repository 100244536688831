<template>
  <div>
    <Header />

    <div class="contact-us">
      <!-- <b-container> -->
      <div class="">
        <div class="contact-top breadcrumbs d-flex align-items-center">
          <div class="container position-relative d-flex flex-column align-items-center">
            <h3>{{ $t("contact.Contact_Us") }}</h3>
          </div>
        </div>
        <!-- <img src="../../../assets/images/info/about-us.jpg" alt="" /> -->
      </div>
      <b-container>
        <b-row>
          <b-col sm="12" md="6">
            <div class="banner-box1">
              <h1>{{ $t("contact.Contact_Us") }}</h1>
              <div class="brush-area">
                <span class="icon-brush-6 icon-title" style="color: #366ce3"></span>
              </div>
              <div class="banner-desc-area">
                <p>
                  {{ $t("contact.Contact_Us_p") }}
                </p>
              </div>
              <div class="banner-btn-area"></div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="banner-box2 next-image-parent">
              <div class="next-img-box">
                <div>
                  <img
                    alt="Contact us"
                    title="Contact us"
                    src="https://www.bhartiaxa.com/sites/default/files/2021-07/contactus-hero.svg?w=1080&amp;q=75"
                    decoding="async"
                    data-nimg="fixed"
                    class="banner-img next-img"
                    srcset="
                      https://www.bhartiaxa.com/sites/default/files/2021-07/contactus-hero.svg?w=640&amp;q=75  1x,
                      https://www.bhartiaxa.com/sites/default/files/2021-07/contactus-hero.svg?w=1080&amp;q=75 2x
                    "
                  /><noscript
                    ><img
                      alt="Contact us"
                      title="Contact us"
                      srcSet="https://www.bhartiaxa.com/sites/default/files/2021-07/contactus-hero.svg?w=640&amp;q=75 1x, https://www.bhartiaxa.com/sites/default/files/2021-07/contactus-hero.svg?w=1080&amp;q=75 2x"
                      src="https://www.bhartiaxa.com/sites/default/files/2021-07/contactus-hero.svg?w=1080&amp;q=75"
                      decoding="async"
                      data-nimg="fixed"
                      style="
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        box-sizing: border-box;
                        padding: 0;
                        border: none;
                        margin: auto;
                        display: block;
                        width: 0;
                        height: 0;
                        min-width: 100%;
                        max-width: 100%;
                        min-height: 100%;
                        max-height: 100%;
                      "
                      class="banner-img next-img"
                      loading="lazy"
                  /></noscript>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="contact-form">
          <b-col sm="12" md="6">
            <div>
              <div class="contact-info">
                <div class="">
                  <div class="contact-info-block info-call">
                    <i class="fa-solid fa-phone"></i>
                    <div class="pl-1">
                      <h3>{{ $t("contact.Call_Us") }}</h3>
                      <p>{{ $t("contact.phone") }}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="contact-info-block info-email">
                    <i class="fa-solid fa-envelope"></i>
                    <div class="pl-1">
                      <h3>{{ $t("contact.Email") }}</h3>
                      <p>{{ $t("contact.Email_p") }}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="contact-info-block">
                    <a href="https://goo.gl/maps/bo3EwYsP4SWG6eUS7" target="_blank"
                      ><i class="fa-solid fa-location-dot"></i
                    ></a>
                    <div class="pl-1">
                      <h3>{{ $t("contact.Address") }}</h3>
                      <p>
                        {{ $t("contact.Address_p") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="card-message">
              <!-- <h2 class="heading-message">Drop Us a Message</h2> -->
              <h5>
                {{ $t("contact.Feel_free_to_contact") }}
              </h5>
              <validation-observer ref="simpleRules">
                <b-form>
                  <b-row>
                    <!-- Name-->
                    <b-col md="12">
                      <b-form-group>
                        <label>{{ $t("contact.Name") }}</label>
                        <validation-provider #default="{ errors }" name="name" rules="required">
                          <b-form-input
                            v-model="name"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('contact.Write_your_name')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Email-->
                    <b-col md="12">
                      <b-form-group>
                        <label>{{ $t("contact.Email") }}</label>
                        <validation-provider
                          #default="{ errors }"
                          name="email"
                          rules="required|email"
                        >
                          <b-form-input
                            v-model="email"
                            :state="errors.length > 0 ? false : null"
                            type="email"
                            :placeholder="$t('contact.Write_your_email')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Message-->
                    <b-col md="12">
                      <b-form-group>
                        <label>{{ $t("contact.Message") }}</label>
                        <validation-provider #default="{ errors }" rules="required" name="message">
                          <b-form-textarea
                            v-model="message"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('contact.Write_your_message')"
                            rows="7"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12">
                      <b-button variant="primary" type="submit" @click.prevent="validationForm">
                        {{ $t("contact.Send") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </div>
          </b-col>
        </b-row>

        <div class="contact-social">
          <h2>{{ $t("contact.Follow_Us") }}</h2>
          <ul>
            <li>
              <a href="#" target="_blank"><i class="fa-brands fa-facebook"></i></a>
            </li>
            <li>
              <a href="https://www.instagram.com/"><i class="fa-brands fa-instagram"></i></a>
            </li>
            <li>
              <a href="#"><i class="fa-brands fa-linkedin"></i></a>
            </li>
            <li>
              <a href="https://twitter.com/"><i class="fa-brands fa-twitter"></i></a>
            </li>
          </ul>
        </div>
      </b-container>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/layouts/landpage/HeaderHome.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BContainer,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormDatepicker,
  BFormTextarea,
  BMedia,
  BImg,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";

export default {
  components: {
    Header,
    Footer,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BContainer,
    BForm,
    BRow,
    BCard,
    BCol,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BMedia,
    BImg,
    BCardText,
  },
  data() {
    return {
      name: "",
      email: "",
      message: "",
    };
  },
};
</script>

<style scoped>
/* .contact-us {
  margin-top: 110px;
  margin-bottom: 75px;
} */

.contact-us .contact-top {
  height: 290px;
}
.contact-us .contact-form {
  margin-top: 50px;
  border-radius: 15px;
}
.contact-us .contact-form .card {
  background-color: #f8f8f8;
  border-radius: 1.428rem;
}
.contact-form img {
  width: 450px;
  margin-top: 51px;
  margin-bottom: 40px;
}
@media (max-width: 640px) {
  .contact-form img {
    display: none;
  }
}
.card-message h5 {
  color: #fff;
  font-weight: 700;
  font-size: 19px;
}
/*  --------------------------------------------  */

.contact-us .contact-us .contact-top {
  width: -webkit-fill-available;
}
.contact-us .contactus-heading {
  /* border-bottom: 1px solid #cbcbcb; */
  font-size: 44px;
  line-height: 60px;
  margin: 29px 0;
  padding-bottom: 15px;
}

.contact-us .contact-heading {
  font-size: 30px;
}
.contact-us .contact-text {
  margin: 15px 0;
  font-size: 15px;
  font-weight: 700;
}
.contact-us .contact-card {
  padding: 15px 0 25px 0;
}
.contact-us .media-heading {
  font-weight: 600;
  font-size: 20px;
}
.contact-us .media-body .card-text {
  font-size: 15px;
  font-weight: 500;
}
.media-aside {
  margin-top: 1rem;
}
.contact-us .socialIcon {
  text-align: center;
}
.contact-us .socialIcon a {
  display: contents;
}

.contact-us .socialIcon .fa-brands {
  font-size: 40px;
  margin-right: 30px;
  margin-bottom: 20px;
}

.contact-us .social-heading {
  text-align: center;
  font-size: 23px;
  margin-bottom: 20px;
  font-weight: 600;
}

.contact-us .card-message {
  /* padding-bottom: 53px; */
  padding: 40px;
}
.card-message label {
  font-size: 16px;
  font-weight: 500;
  color: #d8d8d8;
}
.contact-us .heading-message {
  margin-top: 15px;
  font-size: 30px;
}
.contact-us .card-message form {
  margin-top: 20px;
}

.contact-us .card-message form .btn-primary {
  border-color: #366ce3 !important;
  background-color: #366ce3 !important;
}
</style>
<style scoped>
@media (min-width: 750px) {
  .banner-box1 {
    margin-top: 90px;
  }
}
.banner-box1 h1 {
  font-size: 40px;
  color: #000;
}
.banner-box1 p {
  font-size: 17px;
  color: #000;
}
@media (max-width: 650px) {
  .banner-box1 {
    text-align: center;
    margin-top: 20px;
  }
}
@media (min-width: 850px) {
  .next-img-box img {
    width: 496px;
    height: 303px;
  }
}
@media (max-width: 550px) {
  .next-img-box img {
    width: 320px;
    height: 195px;
  }
}
.global-banner-template .brush-area {
  margin-top: -2.5rem;
  margin-bottom: -2rem;
}
[class*=" icon-"],
[class^="icon-"] {
  font-family: icomoon !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: -2.5rem;
  font-size: 6rem;
  line-height: 1;
}
.icon-brush-6:before {
  content: "";
}
.brush-area {
  margin-top: -2.5rem;
}

.contact-info {
  margin-top: 80px;
  margin-bottom: 90px;
}
.contact-us .fa-location-dot {
  font-size: 17px;
  color: #366ce3;
}
.contact-us .fa-location-dot:hover {
  color: #166fb6;
}
.contact-us .fa-phone {
  font-size: 17px;
  color: #366ce3;
}
.contact-us .fa-phone:hover {
  color: #166fb6;
}
.contact-us .fa-envelope {
  font-size: 17px;
  color: #366ce3;
}
.contact-us .fa-envelope:hover {
  color: #166fb6;
}
.contact-info-block {
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.contact-info-block i {
  margin-bottom: 8px;
}
.contact-info-block h3 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}
.contact-info-block p {
  font-size: 16px;
  color: #cdcdcd;
}

.contact-social h2 {
  color: #000;
  text-align: center;
  font-weight: 700;
}

.contact-social ul {
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-end;
  justify-content: center;
  list-style: none;
}
.contact-social ul li {
  margin: 12px;
}
.contact-social ul li .fa-brands {
  font-size: 40px;
  color: #366ce3;
}
.contact-social ul li .fa-brands:hover {
  color: #166fb6;
}
.card-message {
  /* background-image: url("../../../assets/images/info/aaa.jpg"); */
  /* background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
}
.info-call,
.info-email {
  /* border-right: 2px solid #e0e0e0; */
}
.contact-social {
  margin-top: 25px;
}
</style>
<style>
.contact-top {
  background-image: url(../../../assets/images/info/contact-header.jpg);
  padding: 140px 0 60px 0;
  min-height: 30vh;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.breadcrumbs:before {
  content: "";
  background-color: rgb(1 4 8 / 74%);
  position: absolute;
  inset: 0;
}
.breadcrumbs h3 {
  font-size: 56px;
  font-weight: 500;
  color: #fff;
  font-family: var(--font-secondary);
}

.fa-solid {
  background-color: #fff;
  padding: 14px;
  border-radius: 10px;
  cursor: pointer;
}

.contact-form {
  background-image: url(../../../assets/images/info/contactUs-2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
